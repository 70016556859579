<template>
    <div class="myNote">
        <Header />
        <div class="banner">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>我的笔记</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="myNote-container">

            <div class="myNote-panel">

                <empty height="500px" v-if="empty"/>

                <div class="item" v-for="(item,index) in list" :key="index">
                    <div class="head">
                      <div class="title">
                        <div class="type">{{ formatType(item.type)}}</div>
                        <div>  {{ item.title}}</div>
                     </div>
                      <div style="width: 140px;margin-left: 10px;">
                      <div class="action">
                        <el-button type="primary" size="mini" plain @click="toDetail(index)">查看</el-button>
                        <el-button type="danger" size="mini" plain @click="handleDel(item.id)">删除</el-button>
                      </div>
                    </div>
                    </div>
                    <div class="body">笔记：{{ item.context }}</div>
                </div>
               
                <div class="page">
                    <el-pagination :hide-on-single-page="true" @current-change="handelChange" background
                        :page-size="pageSize" layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Empty from '@/components/Empty'
import Footer from "../components/Footer.vue"
import{GetUserNote,DelNote} from '@/api/list'

export default {

    components: {
        Header,
        Empty,
        Footer,
        Empty
    },
    data(){

        return {

            list:[],
            total:1,
            page:1,
            pageSize:6,
            empty:false,

        }
    },

    created(){

      this.getUserNoteData();
    },
    methods:{

        //加载数据
        getUserNoteData(){
            GetUserNote(this.$store.state.user.category.id,this.page,this.pageSize).then(res=>{

                this.list = res.data;
                this.total = res.total;
                this.empty = this.list.length===0;

            })
        },

        //详情
        toDetail(index){

            let idx = (this.page-1)*this.pageSize+index

            this.$router.push({path:'noteDetail',query:{idx:idx}})
        },
        //格式化题型
        formatType(type){

            switch(type){
                case 1:
                return '多选';
                case 2:
                return '判断';
                case 3:
                return '单选'
                default:
                return '未知'
            }
        },

        handleDel(id){

            this.$confirm('此操作将永久删除该笔记, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {


            DelNote(id).then(()=>{
                this.$message.success('删除成功')
                this.getUserNoteData();
            })

            
        })

        },
          //分页
          handelChange(val){

            this.page=val;
            this.getUserNoteData();  
    }
    }


} 
</script>

<style lang="less" scoped>
.myNote {
    background-color: #f2f2f2;

    .banner {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .nav {

            width: 1200px;
        }
    }

    .myNote-container {
        width: 100%;
        min-height: 600px;

        .myNote-panel {

            background-color: #fff;
            width: 1150px;
            margin: 0 auto;
            padding: 25px;
            margin-bottom: 20px;
            border-radius: 10px;

            .item{
                margin-top: 20px;
                cursor: pointer;
                &:hover{
                        .action{
                            display: block !important;
                        }
                        .title{
                            color: #3B89FF !important;
                            text-decoration: underline;
                        }
             
                    }
              .head{
                display: flex;

              
                                 
                .title{
                    font-size: 20px;
                    color: #000;
                    flex: 1;
                    display:flex; 
                    .type{
                       
                        flex-shrink: 0;
                        font-size: 12px;
                        background-color: #3B89FF;
                        color: #fff;
                        border-radius: 4px;
                        line-height: 18px;
                        padding: 2px 5px;
                        height: 18px;
                        margin-top: 4px;
                        margin-right: 6px;
                  

                    }

                
                }
                .action{

                    /deep/ .el-button--primary{
                        color: #3B89FF;
                        background: transparent;
                        border-color:#3B89FF;
                    }
                    /deep/ .el-button--danger{
                        color: #FF3B30;
                        background: transparent;
                        border-color:#FF3B30;
                    }
                    

                 display: none;
                 flex-shrink: 0;
                 width: 160px;
                
              }

              }
              .body{
                margin-top: 10px;
                background-color: #F5F9FF;
                padding: 16px;
                border-radius: 10px;
                font-size: 15px;
                color: #000;
              }
            
            }

            .page {
                display: flex;
                justify-content: center;
                margin-top: 30px;
            }
        }
    }

}
</style>